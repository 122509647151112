import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import Api from "../services/Api";
import { authActions } from "./login/store";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

const AccessLoginLoading=()=>{
    // const [accessToken, setAccessToken] = useState(window.location.pathname .startsWith("/AccessLoginLoading") && window.location.pathname.split('/')[2].split("=")[1]);
    const dispatch=useDispatch()
    let history = useHistory();

  
    useEffect(() => {
        let tokenUrl = window.location.pathname.split('/')[2]
        let token = tokenUrl.split('&')[0].split('=')[1]
        let sourceId

        if(tokenUrl.includes("sourceId")){
          sourceId = tokenUrl.split('&')[1].split('=')[1]
        }
        // console.log("ssssToenUrll",tokenUrl,sourceId)
        if (token) {
          let queryParams = [`loginEncryption=${token}`];

        if (sourceId) {
          queryParams.push(`sourceId=${sourceId}`);
        }
        
        Api.changeUrl("https://loginv2.brigosha.com")
        Api.post(`/hiring-auth/returnData?${queryParams.join("&")}`)
           .send((response) => {
              if (typeof response != undefined && response?.show?.type != "error") {
                console.log("sssResponse",response)
                Api.header(response?.accessToken);
              //   setAccessToken(response?.accessToken)
                dispatch(authActions.updateToken({ token: response?.accessToken }));
                dispatch(authActions.updateUser({ user: response?.user }));
                dispatch(authActions.candidateId(response?.candidateId));
              if(response?.candidateId){
                  history.push("/candidate-info");
                  //history.push("/login");
              }
              else if(response?.user?.role?.includes('Super-Admin') || response?.user?.role?.includes('Admin') )
              {  
                history.push("/Dashboard");
              }
              else if(response?.user?.role?.includes('Recruiter')||response?.user?.role?.includes('Sourcer') || response?.user?.role?.includes('Shortlister'))
              {
                history.push("/jd");
              }
              else if(response?.user?.role?.includes('HR')||response?.user?.role?.includes('Panelist'))
              {
                history.push("/dashboard-view");
              }
              else if(response?.user?.role?.includes('Agency'))
              {
                history.push("/exp-candidate-management");
              }
              else if(response?.user?.role?.includes("College-Admin"))
              {
                history.push("/institute-dashboard");
              }
              else
              {
                history.push("/")
              //   window.location.href = "https://newpmt.brigosha.com";
              }
              }
              else if(response?.show?.type === "error"){
                  setTimeout(()=>{
                      window.location.replace("https://login.brigosha.com");
                  },1000)
              }
          }
            );
        }
    }, []);

    return(
        <div style={{height:"100vh",width:"100%",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>
                <Spin />
        </div>
    )
}

export default AccessLoginLoading;