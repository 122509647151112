import {
    Space,
    Table,
    Button,
    PageHeader,
    Row,
    Col,
    Typography,
    Timeline,
    Input,
    Menu,
    Dropdown,
    Avatar,
    Popover,
    Tabs 
  } from "antd";
  import {
    MoreOutlined,
    UserOutlined,
    DownOutlined,
    SearchOutlined
  } from "@ant-design/icons";
  import React, { useState, useEffect } from "react";
  import {authActions} from 'pages/login/store';
import { useDispatch, useSelector } from "react-redux";
import { isExpired } from "react-jwt";
import { useHistory } from "react-router-dom";

const { Title } = Typography;

export default function LogoutPalette(){
    const authstate = useSelector((state) => state.auth);
    const [timer, setTimer] = useState(null);
    const { user} = useSelector((state) => state.auth);
    const dispatch = useDispatch();
     const history = useHistory();
    const logoutHAndler = () => {
      if(user?.role?.includes("Agency")||user?.role?.includes("College-Admin")){
        window.localStorage.clear()
        window.location.replace("https://login.brigosha.com");
      }else{
        // dispatch(authActions.removeUser());
        
        history.goBack();
        history.goBack();
        window.location.replace("https://login.brigosha.com/homePage")
      }
      };
      useEffect(()=>{
        if(isExpired(authstate.token)){
          logoutHAndler();
        }
      })

    return <>
    {user?.role?.includes("Agency") || user?.role?.includes("College-Admin") ?
    <Popover placement="bottomRight" 
     content={<div style={{ cursor: "pointer", }} onClick={logoutHAndler}>
      <span> Logout</span>
      </div>} trigger="click"> 
      <Space style={{fontWeight:"700",cursor:"pointer"}}>
        <UserOutlined />
        {authstate.user.name}
        <DownOutlined />
      </Space>
    </Popover>
    :
      <Space style={{fontWeight:"700",cursor:"pointer"}}>
        <UserOutlined />
        {authstate.user.name}
        {/* <DownOutlined /> */}
      </Space>
    }
</>
}