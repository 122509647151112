import React, { useEffect, useState } from "react";
import classes from './testList.module.css';
import './testList.module.css';
import {
  Layout,
  Table,
  Typography,
  Row,
  Col,
  Input,
  Button,
  Tabs,
  Space,
  Modal,
  Dropdown,
  Card,
  Checkbox,
  Radio,
  Popover,
  Divider,
  List,
} from "antd";
import Settings from '../../assets/settings.png';
import Sort from '../../assets/sort.png';
import filter from "../../assets/filter.png";
import Download from '../../assets/download.png';
import searchIcon from '../../assets/searchIcon.png'
const { TabPane } = Tabs;
import { useDispatch, useSelector } from "react-redux";
import CreateTest from "./create-test";
import {  testActions } from "./store";
import Api from "services/Api";
// import MockTest from "./mock-test";
const { Content } = Layout;
const { Title } = Typography;
import { useHistory } from "react-router";
import CloneTest from "./test-clone-modal"
import { authActions } from "pages/login/store";
import { Notification } from "services";
import { SwapOutlined,SearchOutlined, EyeOutlined,CheckCircleTwoTone,DownloadOutlined, ApiFilled,DownOutlined,CloseOutlined } from "@ant-design/icons";

// import Book from "./../../Book.svg";
import Book from "../../assets/BookIcon.svg";
import Filter from "./filter.cls";
// import classes from "./testL"
const filterObj = new Filter();
import EditTest from "./editTestModal";

const TestList = () => {


  
  let history = useHistory()

  const dispatch = useDispatch()
  const [update,setUpdate] = useState(false)
  const [selectedObj,setSelectedObj]=useState("")

  const { testList
    , count,Status } = useSelector(state => state.test)
  const [loader, setLoader] = useState(false)

  const [sortModalOpen,setSortModalOpen]=useState(false);

  const [idArray,setIdArray]=useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [btnVisible, setBtnVisible] = useState(false);
   const [tableData,setTableData]=useState([]);
  //  const [Status,setStatus]=useState("Active");

   const [secModal, setSecModal] = useState(false);
   const [searchVal,setSearchVal] = useState("")
  
   const [sortType,setSortType] = useState(true); 
   const [sortPopoverVis,setSortPopoverVis] = useState(false);
  
   const [pageChange, setPageChange] = useState(1)
   const [pageSize,setPageSize]=useState(10)
   const [meta,setMeta]=useState("")
   const forceUpdate = React.useCallback(() => updateState({}), []);
   const [, updateState] = React.useState();
   const[details,SetDetails] = useState([]);
   const [filterDetailsFlag,setFilterDetailsFlag] = useState(false);
   const[ids,SetIds] = useState();
   const [sortList, setSortlist] = useState([
    {"key": "duration", label : "Duration" , selected : false, sortKey : "duration"},
    {"key": "totalMarks", label : "Total Marks" , selected : false ,sortKey : "totalMarks"},
    {"key": "overall cut-off", label : "Overall Cut-off", selected : false , sortKey : "testCutt_off"},
    ])
    const [filterStatus,setFilterStatus] = useState( [{title : "Duration", value : "duration",min: 0, max: 0},
    {title : "TotalMarks", value : "totalMarks",min: 0, max: 0},
    {title : "TestCutt_off", value : "testCutt_off",min: 0, max: 0}]);

    
    const [inputObjs,setInputObjs]=useState({
      
        durmin:"",
        durmax:"",
        totalMarksmin:"",
        totalMarksmax:"",
        cuttofmin:"",
        cuttofmax:""
    
    })
   
    


   useEffect(()=>{
    setTableData(testList.map((item,index)=>{
      return {...item, key:item.id}
    }))
  
   

   },[testList])



  const filterTest = (requests) => {
    let request;
    let sortRequest={}
    let filterArr = []
    let statusArr = ["duration", "totalMarks", "testCutt_off", ];
    let selectedVal = sortList.filter(a => a.selected);
    if(selectedVal[0]?.sortKey!=="none"){
      let sortorder={
        order:sortType ? "ASC" : "DESC",
        key:selectedVal[0]?.sortKey
      }
      sortRequest = {
        search:searchVal,
        // page: pageChange,
        // limit:pageSize,
        orderFilter:sortorder
        
      }
    }else{
      sortRequest= {
        search:searchVal,
        // page: pageChange,
        // limit:pageSize,
        
      }
  
    }
  
    statusArr.map(element => {
      
      switch (element) {
        case "duration":
          
          if (filterObj.duration) {
            if((typeof filterObj.durmin!=="string" || typeof filterObj.durmax!=="string") || (filterObj.durmin>filterObj.durmax || filterObj.durmax==="" || filterObj.durmin==="")
            //  || (filterObj.durmin===0 &&filterObj.durmax===0)
             )
            { Notification.error("error","kindly enter both values accordingly")
               filterObj.duration=false
              return 
            }
            filterArr.push({
              key: element,
              min: parseInt(filterObj.durmin),
              max: parseInt(filterObj.durmax)
            })
          
          }
          break;
        case "totalMarks":

          if (filterObj.totalMarks) {
            
            if((typeof filterObj.totmin!=="string" || typeof filterObj.totmax!=="string") || (filterObj.totmin>filterObj.totmax || filterObj.totmax==="" || filterObj.totmin==="")
            //  || (filterObj.totmin===0 &&filterObj.totmax===0)
             )
            { Notification.error("error","kindly enter both values accordingly")
               filterObj.totalMarks=false
              return 
            }
            filterArr.push({
              key: element,
              min: parseInt(filterObj.totmin),
              max: parseInt(filterObj.totmax)
            })
          
          }

          break;
        case "testCutt_off":

          if (filterObj.testCutt_off) {
            if( (typeof filterObj.cutmin!=="string" || typeof filterObj.cutmax!=="string")||(filterObj.cutmin>filterObj.cutmax || filterObj.cutmax==="" || filterObj.cutmin==="") 
            // || (filterObj.cutmin===0 &&filterObj.cutmax===0)
            )
            { Notification.error("error","kindly enter both values accordingly")
               filterObj.testCutt_off=false
              return 
            }
            filterArr.push({
              key: element,
              min: parseInt(filterObj.cutmin),
              max: parseInt(filterObj.cutmax)
            })
         
          
          }

          break;
        default:
          break;
      }

    })

    if (!filterArr) {
      request = {
        ...sortRequest,
        search: searchVal,
        page: pageChange,
        limit:pageSize
      }
    } else {
      let download
      if(requests==="download")
      {
       download={
          download:true
      }
    }
      else{
        download={}
      }
      request = {
        ...download,
        ...sortRequest,
        search: searchVal,
        page: pageChange,
        limit:pageSize,
        rangeFilter: filterArr
      }
    }
   
    getfun(request);
  };

  const getfilterRequest = (key) => {
    switch (key) {
      case "duration" : 
        filterObj.duration = true;
        break;
      case "totalMarks":
        filterObj.totalMarks=true
        break;
        case "testCutt_off":
        filterObj.testCutt_off=true
        break;
      default:
        break;
    }
  }

  const setFilterFilter = (type,valueType = null, value) => {
     
    if (valueType === "min") {
      switch (type) {
        case "duration":
          filterObj.durmin = value
          break;
        case 'totalMarks':
          filterObj.totmin = value;
          break;
        case 'testCutt_off':
          filterObj.cutmin = value;
          break;
        default:
          break;
      }
    } else if (valueType === "max") {
      switch (type) {
        case "duration":
          filterObj.durmax = value
          break;
        case 'totalMarks':
          filterObj.totmax = value;
          break;
        case 'testCutt_off':
          filterObj.cutmax = value;
          break;
        default:
          break;
      }
    }else if (valueType === "num") {
      switch (type) {
        case "duration":
          filterObj.durnum = value
          break;
        case 'totalMarks':
          filterObj.totnum = value;
          break;
        case 'testCutt_off':
          filterObj.cutnum = value;
          break;
        default:
          break;
      }
    }
  }

  const resetFilter = () => {
  
    filterObj.resetFitler();
    setInputObjs({ durmin:"",
    durmax:"",
    totalMarksmin:"",
    totalMarksmax:"",
    cuttofmin:"",
    cuttofmax:""})
    let testList = [];
    filterTest()
    forceUpdate();}

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {

      setSelectedRow(selectedRowKeys);
      if (selectedRowKeys.length === 0) {
        setBtnVisible(false);
      } else {
        setBtnVisible(true);
      }
    },
    onSelect: (record, selected, selectedRows) => { 
      setIdArray((prev)=>(
        [...prev,record.id]
      ));
    
    },
    onSelectAll: (selected, selectedRows, changeRows) => { },
    getCheckboxProps: (record) => ({}),
  };

  const showSecModal = () => {
    setSecModal(true)
  };

  const cancelHandler = () => {
    setSecModal(false)
  }

  const handleCancel = () => {
    setSecModal(false);
  };

  // useEffect(() => {
  //   setLoader(true)
  //   Api.post("/hiring-tests/getAllTests")
  //     .params({status:Status})
  //     .send((response) => {
  //       if (typeof response != "undefined") {
  //         setLoader(false)
         
  //         dispatch(testActions.updateTest( response ));

  //       }
  //     });
  // }, []);

  const customFilterPopover = (type = null,  logo) => {
    let contentBody;

    switch (type) {

      case "duration" : 
  
      contentBody = <div className="sort-popover">
             {/* <Row style={{marginBotom:"40px"}}> */}
              {/* <div >
              </div> */}
              <div> <span className="sort-heading-css">Filter</span>
                {/* <Button type="text" size="large" className="float-right"
                  onClick={()=>{
                    resetFilter();
                    
                  
                    let temp = filterStatus;
                    temp[type] = !temp[type];
                    setFilterStatus(temp);
                    forceUpdate();
                  }}
                >Reset</Button> */}
              </div>
              {/* </Row> */}
              {/* <hr style={{color:"#BBBDBF",backgroundColor:"#BBBDBF"}}></hr> */}
              <div>
                <Input value={inputObjs.durmin===0 ?"":inputObjs.durmin} type="number" min={0} placeholder="Enter the minimum range" onChange={(e) => {
                  setFilterFilter(type,"min", e.target.value)
                  let obj=inputObjs
                  
                  obj["durmin"]=e.target.value
              
                  setInputObjs(obj)
                  forceUpdate()
                }} style={{marginTop:"10px"}}/>
                <br></br>
                <br></br>
                <Input value={inputObjs.durmax===0?"":inputObjs.durmax} type="number" min={0} placeholder="Enter the maximum range"
                  onChange={(e) => {
                    setFilterFilter(type, "max", e.target.value);
                    let obj=inputObjs
                    // delete(obj.dur.max)
                    obj["durmax"]=e.target.value
                    setInputObjs(obj)
                    forceUpdate()
                  }}
                />
                {/* <div className="Orr">
                <hr class="hr-text" data-content="Or"></hr> 
                <Divider style={{color:"#BBBDBF",marginTop:"0px",marginBottom:"0px"}}>Or</Divider>
                </div>  
                 */}
                {/* <Input type="number" min={0} style={{marginBottom:"20px"}} 
                  placeholder="Enter the maximum range"
                  onChange={(e) => {
                    setFilterFilter(type, "num", e.target.value);
                  }}
                />              */}
              </div>
              <div className="sort-action" style={{marginTop:"1rem"}}>
                <Button className="cancel" 
                onClick={()=>{
                 
                  let temp = filterStatus;
                  temp[type] = !temp[type];
              
                  setFilterStatus(temp);
                  forceUpdate();
                }}
      >
                  CANCEL</Button>
                <Button className="float-right ok" onClick={() => {
                  if(parseInt( inputObjs["durmax"])<parseInt(inputObjs["durmin"]))
                  {
                    Notification.error("Maximum duration should be greater than or equal to minimum duration!");
                    return;
                  }
                  getfilterRequest(type);
                  filterTest();
                  forceUpdate();
                }}>OK</Button>
              </div>
            </div>
    
            return <Popover
              placement="bottom"
              width="300px"
              style={{width:"200px"}}
              content={contentBody}
              trigger="hover"
              visible={filterStatus[type]}
              onVisibleChange={()=>{
                let temp = filterStatus;
                temp[type] = !temp[type];
                setFilterStatus(temp);
                forceUpdate();
              }}
            >
              {logo}
            </Popover>

   case "totalMarks" : 
  
      contentBody = <div className="sort-popover">
             <div> <span className="sort-heading-css">Filter</span>
                {/* <Button type="text" size="large" className="float-right"
                 onClick={() =>{resetFilter();
                  setIsReset(true);
                  let temp = filterStatus;
                  temp[type] = !temp[type];
                  setFilterStatus(temp);
                }}
                >Reset</Button> */}
              </div>
              {/* <hr style={{color:"#BBBDBF",backgroundColor:"#BBBDBF"}}></hr> */}
              <div>
                <Input value={inputObjs.totalMarksmin===0?"":inputObjs.totalMarksmin} type="number" min={0} placeholder="Enter the minimum range" onChange={(e) => {

                  setFilterFilter(type, "min", e.target.value)
                  let obj=inputObjs
                  // delete(obj.dur.max)
                  obj["totalMarksmin"]=e.target.value
                  setInputObjs(obj)
                  forceUpdate()
                }} style={{marginTop:"10px"}}/>
                <br></br>
                <br></br>
                <Input value={inputObjs.totalMarksmax===0?"":inputObjs.totalMarksmax} type="number" min={0} placeholder="Enter the maximum range"
                  onChange={(e) => {
                   
                    setFilterFilter(type, "max", e.target.value);
                    let obj=inputObjs
                    // delete(obj.dur.max)
                    obj["totalMarksmax"]=e.target.value
                    setInputObjs(obj)
                    forceUpdate()
                  }}
                />
                {/* <div className="Orr">
                <hr class="hr-text" data-content="Or"></hr> 
                <Divider style={{color:"#BBBDBF",marginTop:"0px",marginBottom:"0px"}}>Or</Divider>
                </div>   */}
                
                {/* <Input type="number" min={0} style={{marginBottom:"20px"}} 
                  placeholder="Enter the maximum range"
                  onChange={(e) => {
                    setFilterFilter(type, "num", e.target.value);
                  }}
                />              */}
              </div>
              <div className="sort-action" style={{marginTop:"1rem"}}>
                <Button className="cancel" 
                onClick={()=>{
                 
                  let temp = filterStatus;
                  temp[type] = !temp[type];
              
                  setFilterStatus(temp);
                  forceUpdate();
                }}
      >
                  CANCEL</Button>
                <Button className="float-right ok" onClick={() => {
                  if(parseInt( inputObjs["totalMarksmax"])<parseInt(inputObjs["totalMarksmin"]))
                  {
                    Notification.error("Maximum marks should be greater than or equal to minimum marks!");
                    return;
                  }
                  getfilterRequest(type);
                  filterTest();
                  forceUpdate();
                  let temp = filterStatus;
                  temp[type] = !temp[type];
                  setFilterStatus(temp);
                }}>OK</Button>
              </div>
            </div>
    
            return <Popover
              placement="bottom"
              width="300px"
              style={{width:"200px"}}
              content={contentBody}
              trigger="hover"
              visible={filterStatus[type]}
              onVisibleChange={()=>{
                let temp = filterStatus;
                temp[type] = !temp[type];
                setFilterStatus(temp);
                forceUpdate();
              }}
            >
              {logo}
            </Popover>

   case "testCutt_off" : 
  
      contentBody = <div className="sort-popover">
            <div>
              <span className="sort-heading-css">Filter</span>
                {/* <Button type="text" size="large" className="float-right"
                  onClick={() =>{resetFilter();
                    setIsReset(true);
                    let temp = filterStatus;
                    temp[type] = !temp[type];
                    setFilterStatus(temp);
                  }}
                >Reset</Button> */}
              </div>
        {/* <hr style={{color:"#BBBDBF",backgroundColor:"#BBBDBF"}}></hr> */}
        <div>
          <Input value={inputObjs.cuttofmin} type="number" min={0} placeholder="Enter the minimum range" onChange={(e) => {
            setFilterFilter(type, "min", e.target.value)
            let obj=inputObjs
            // delete(obj.dur.max)
            obj["cuttofmin"]=e.target.value
            setInputObjs(obj)
            forceUpdate()
          }} 
          style={{marginTop:"10px"}}
          />
          <br></br>
          <br></br>
          <Input value={inputObjs.cuttofmax} type="number" min={0} placeholder="Enter the maximum range"
            onChange={(e) => {
              setFilterFilter(type, "max", e.target.value);
              let obj=inputObjs
              // delete(obj.dur.max)
              obj["cuttofmax"]=e.target.value
              setInputObjs(obj)
              forceUpdate()
            }}
          />
          {/* <div className="Orr">
          <Divider style={{color:"#BBBDBF",marginTop:"0px",marginBottom:"0px"}}>Or</Divider>
          </div>   */}
          
          {/* <Input type="number" min={0} style={{marginBottom:"20px"}} 
            placeholder="Enter a single value"
            onChange={(e) => {
              setFilterFilter(type, "num", e.target.value);
            }}
          />              */}
        </div>
        <div className="sort-action" style={{marginTop:"1rem"}}>
          <Button className="cancel" 
          onClick={()=>{
           
            let temp = filterStatus;
            temp[type] = !temp[type];
           
            setFilterStatus(temp);
            forceUpdate();
          }}
>
            CANCEL</Button>
          <Button className="float-right ok" onClick={() => {
             if(parseInt( inputObjs["cuttofmax"])<parseInt(inputObjs["cuttofmin"]))
             {
               Notification.error("Maximum cut-off should be greater than or equal to minimum cut-off!");
               return;
             }
            getfilterRequest(type);
            filterTest();
            forceUpdate();
            let temp = filterStatus;
          temp[type] = !temp[type];
          setFilterStatus(temp);
          }}>OK</Button>
        </div>
      </div>

      return <Popover
        placement="bottom"
        width="300px"
        style={{width:"200px"}}
        content={contentBody}
        trigger="hover"
        visible={filterStatus[type]}
        onVisibleChange={()=>{
          let temp = filterStatus;
          temp[type] = !temp[type];
          setFilterStatus(temp);
          forceUpdate();
        }}
      >
        {logo}
      </Popover>


      default:
        if(type === 'duration' || type == 'totalMarks' || type == 'testCutt_off'){
        contentBody = <div className="sort-popover">
        <div>
          <span className="sort-heading-css">Filter</span>
            <Button type="text" size="large" className="float-right"
              onClick={resetFilter}
            >Reset</Button>
          </div>
    {/* <hr style={{color:"#BBBDBF",backgroundColor:"#BBBDBF"}}></hr> */}
    <div>
      <Input type="number" min={0} placeholder="Enter the minimum range" onChange={(e) => {
        setFilterFilter(type, "min", e.target.value)
      }} style={{marginTop:"10px"}}/>
      <br></br>
      <br></br>
      <Input type="number" min={0} placeholder="Enter the maximum range"
        onChange={(e) => {
          setFilterFilter(type, "max", e.target.value);
        }}
      />
      <div className="Orr">
      <Divider style={{color:"#BBBDBF",marginTop:"0px",marginBottom:"0px"}}>Or</Divider>
      </div>  
      
      {/* <Input type="number" min={0} style={{marginBottom:"20px"}} 
        placeholder="Enter a single value"
        onChange={(e) => {
          setFilterFilter(type, "num", e.target.value);
        }}
      />              */}
    </div>
    <div className="sort-action">
      <Button className="cancel" 
      onClick={()=>{
       
        let temp = filterStatus;
        temp[type] = !temp[type];
      
        setFilterStatus(temp);
        forceUpdate();
      }}
>
        CANCEL</Button>
      <Button className="float-right ok" onClick={() => {
        getfilterRequest(type);
        filterTest();
        forceUpdate();
      }}>OK</Button>
    </div>
  </div>

  return <Popover
    placement="bottom"
    width="300px"
    style={{width:"200px"}}
    content={contentBody}
    trigger="click"
    visible={filterStatus[type]}
    onVisibleChange={()=>{
      let temp = filterStatus;
      temp[type] = !temp[type];
      setFilterStatus(temp);
      forceUpdate();
    }}
  >
    {logo}
  </Popover>
  }
    break;
   
    }
  };


const data=(status)=>{
  
  const Data=tableData.filter(item=>{
    if(item.status===status)
    {
      return item;
    }
  })

  return Data;

}

  const columns = [
    {
      title: <div className={classes.headerName} style={{minWidth:"5rem",}}>
              <h1 className={classes.nameMargin}>TITLE </h1>
              </div>,
      dataIndex: "title",
      key: "title",
      width:"10rem",
      render: (text, record) => (
        <div style={{maxWidth:"10rem",}} >
        <a onClick={() =>{
          setUpdate(true)
          setSelectedObj(record)
        }} style={{color:"#000000",fontWeight:"600",}}><Typography.Text style={{width:"10rem"}} ellipsis={{tooltip:true}}>{text}</Typography.Text></a>
        </div>
      ),
    },

    {
      title: <div className={classes.headerName} style={{minWidth:"5rem"}}>
                      <h1>ACTION </h1>
              </div>,
      dataIndex: "action",
      key: "action",
      width:"8rem",
 
      render: (_, record) => {
       
        return(
        <div style={{width:"10rem"}}>
        {
              record.createdMarks === 0 ?
          <Button onClick={() => { history.push(`/test/${record.id}`) }} 
          style={{ backgroundColor: '#6BCB77',color:"white",fontWeight:"bold",height:"1.8rem",width:"6rem",borderRadius:"5px",fontSize:"0.9rem",border:"0"}} 
          size="small">
            ADD
          </Button> :
          <Button onClick={() => { history.push(`/test/${record.id}`) }} 
          style={{  borderColor: "#1e90ff", color: "#1e90ff",fontWeight:"bold",height:"1.7rem",width:"6rem",borderRadius:"6px",fontSize:"0.9rem"}} 
          size="small">
            EDIT
          </Button>
      }
        
        </div>)
      },
    },
    {
      title:  <div className={classes.headerName} style={{minWidth:"8rem",}}>
                  <h1>DURATION  {customFilterPopover("duration",<DownOutlined style={{marginLeft:"10px",}}/>)}</h1>
              </div>,
      dataIndex: "duration",
      key: "duration",
      index:2,
      width:"9rem",
     
    
      render:(text,record)=>{
        return(
          <div style={{minWidth:"8rem"}}>
            {record?.duration} 
          </div>)}
    },
    {
      title:  <div className={classes.headerName} style={{minWidth:"7rem",}}>
                        <h1>TOTAL MARKS  {customFilterPopover("totalMarks",<DownOutlined style={{marginLeft:"10px"}}/>)}</h1>
                </div>,
      dataIndex: "totalMarks",
      key: "totalMarks",
      index:3,
      width:"200px",
      // sorter: {},
      render:(text,record)=>{
        return(
          <div style={{minWidth:"12rem"}}>
            { record?.createdMarks === record?.totalMarks ? <span style={{color:"#6BCB77"}}>{record?.createdMarks}</span> :
            <span style={{color:"red"}}>{record?.createdMarks}</span>}<span> / </span><span style={{color:"#1e90ff"}}> {record?.totalMarks}</span>
          </div>)}
    },
    {
      title:  <div className={classes.headerName} style={{minWidth:"7rem",}}>
                      <h1>OVERALL CUT-OFF  {customFilterPopover("testCutt_off",<DownOutlined style={{marginLeft:"10px",}}/>)}</h1>
                </div>,
      dataIndex: "testCutt_off",
      key: "testCutt_off",
      width:"250px",
      render:(text,record)=>{
        return(
          <div style={{minWidth:"10rem"}}>
            {record?.testCutt_off}
          </div>)}
    },
    {
      title:  <div className={classes.headerName} style={{Width:"15rem",}}>
                      <h1>SECTIONS DETAILS</h1>
                </div>,
      dataIndex: "sections",
      key: "sections",
      width:"150px",
      render:(text,record)=>{
        let rec=record?.sections
        let ids=record?.id
        return(
          <div style={{width:"10rem"}}>
            <EyeOutlined onClick={()=>{
             SetIds(record?.id)
             SetDetails(rec)
             setSecModal(true)
           }}
          />
         
       </div>
        )
     
      }
    },
   
  ];

  
  const sortValue = () => {
    let request;
    
    let selectedVal = sortList.filter(a => a.selected);
      if(selectedVal[0].sortKey!=="none"){
        let sortorder={
          order:sortType ? "ASC" : "DESC",
          key:selectedVal[0].sortKey
        }
        request = {
          search:searchVal,
          page: pageChange,
          orderFilter:sortorder
          
        }
      }else{
        request= {
          search:searchVal,
          page: pageChange,
          
        }
    
      }
    
       getfun(request);
  }

  const getfun = (request) => {
 
    const Val= {

      ...request,
      page:pageChange,
      limit:pageSize,
      status:Status
    }
    setLoader(true)
    if(request?.download===true)
    {
      Api.post("/hiring-tests/getAllTests")
      .params(Val)
      .send((response) => {
        if (typeof response != "undefined") {
          setLoader(false)
        
          window.open(response,"_blank")
         

        }
      });
      return
    }
    
  
    Api.post("/hiring-tests/getAllTests")
      .params(Val)
      .send((response) => {
        if (typeof response != "undefined") {
          setLoader(false)
        
          setMeta(response.meta)
          dispatch(testActions.updateTest(response ));

        }
      });
  }

  

  function onSearch(e) {

    let value = e.target.value;


    setLoader(true);
    if (value) {
      Api.post(`/hiring-tests/getAllTests?search=${value}`).send(
        (response) => {
          if (typeof response != "undefined") {
            setLoader(false);
        
            dispatch(testActions.updateTest(response ));
          }
        }
      );
    }
    else if (value === "") {
      getfun()
    }
  }

  const activeFilterList = ()=>{
    let list = [];
    
    filterStatus.map(element => {
     
      switch(element.value){
        case "duration": 
        if(filterObj.duration){

          list.push({
            ...element,
            discription : filterObj.durmin + "-" + filterObj.durmax,
            min : filterObj.durmin,
            max : filterObj.durmax,
            num :filterObj.durnum
          })

        }
        break;
        case "totalMarks":
        if(filterObj.totalMarks){
         
          list.push({
            ...element,
            discription : filterObj.totmin + "-" + filterObj.totmax,
            min : filterObj.totmin,
            max : filterObj.totmax,
            num :filterObj.totnum
          })
        }
        break;

        case "testCutt_off":
          if(filterObj.testCutt_off){
            list.push({
              ...element,
              discription : filterObj.cutmin + "-" + filterObj.cutmax,
              min : filterObj.cutmin,
              max : filterObj.cutmax,
              num :filterObj.cutnum
            })
          }
          break;

        default :
        break;    
      }
    })
    return list;
  }

  const sortPopover = (type = null, logo) => {
    let content;
    switch (type) {
      case "sort":
        content = <div className="sort-popover">
          <div className="sorting-radio-container"> <span className="sort-heading-css">Sort</span> <span className="float-right" style={{cursor:"pointer"}} onClick={() =>{
            let array=  sortList.map((element) =>{
                             if(element.selected===true)
                             {
                                element["selected"]=false
                             }
                             return element
                          })
                          setSortlist(array)
                          setTimeout(() =>{
                            filterTest()
                          },300)
                          }}>Reset</span></div>
          <div style={{marginTop:"15px"}}> <span className="sort-heading-css" style={{fontSize:"19px"}}>Sort Order</span></div>
          <div>

      <div className="sorting-radio-container">
        <Title style={{fontSize : "15px" , display : "inline",fontWeight:"30px"}}>ASCENDING</Title> <Radio 
        value={true} style={{float : "right"}} checked={sortType} 
        onClick={()=>{
          setSortType(true)
        }}></Radio>
      </div>
      <div className="sorting-radio-container">
      <Title style={{fontSize : "15px", display : "inline"}}>DESCENDING</Title> 
      <Radio value={false} style={{float : "right"}} 
      checked={!sortType}
      onClick={()=>{
        setSortType(false)
      }}
      ></Radio>
      </div>
      
      <div style={{marginTop:"15px",marginBottom:"-5px"}}> <span className="sort-heading-css" style={{fontSize:"19px"}}>Sort By</span></div>
      </div>
      <ul className="sorting-list-css">
      {
              sortList.map((item, index) =>
                <li key={index}
                  className={item.selected ? "select-sorting-menu" : ""}
                  onClick={() => {
                    let temp = sortList.slice();
                    temp.map((e,i) => {
                      if(index == i){
                        e.selected = true;
                      } else {
                        e.selected = false;
                      }
                    })
                
                    setSortlist(temp);
                  }}
                >{item.label} <span className="float-right"> <span className={!item.selected ? "check-icon" : ""}><CheckCircleTwoTone /></span></span></li>)
            }
          </ul>

          <div className="sort-action">
            <Button className="cancel" onClick={() => setSortPopoverVis(false)}>CANCEL</Button>
            <Button className="float-right ok" 
              onClick={() => {
              sortValue()
              setSortPopoverVis(false)}}>OK</Button>
          </div>
        </div>

        return <Popover
          placement="bottom"
          content={content}
          trigger="click"
          visible={sortPopoverVis}
          onVisibleChange={() =>{
            if (sortPopoverVis){
              setSortPopoverVis(false)
            }else{
              setSortPopoverVis(true)
            }}
          }
        >
          {logo}
        </Popover>

         break;
        case "filter-details":
          content = <div className="sort-popover">

          <div> <span className="sort-heading-css">ALL Filter(s)</span>
          <Button type="text" size="large" className="float-right"
          onClick={resetFilter}
          >Reset</Button></div>
          <List
          dataSource={activeFilterList()}
          renderItem={(item) => (
            <List.Item key={item.title}>
              <List.Item.Meta
                title={<span>{item.title==="TestCutt_off"?"Overall cut-off":item.title}</span>}
                description={<span>{item.discription  }</span>}
              />
              {/* <div><CloseOutlined/></div> */}
            </List.Item>
          )}
        />

          <div className="sort-action">
            <Button className="cancel" onClick={() => { setFilterDetailsFlag(false)}}>CANCEL</Button>
            <Button className="float-right ok"
            onClick={() => { setFilterDetailsFlag(false)}}
            >OK</Button>
          </div>
        </div>

        return <Popover
          placement="bottom"
          content={content}
          trigger="click"
          visible={filterDetailsFlag}
          onVisibleChange={() => { 
            if (filterDetailsFlag){
              setFilterDetailsFlag(false);
            }else{
              setFilterDetailsFlag(true)
            }
            }}
        >
          {logo}
        </Popover>
        break;
  }}

  const logoutHAndler = () => {
    if(user?.role?.includes("Agency")){
      window.localStorage.clear()
    }
    window.location.replace("https://login.brigosha.com");
    // dispatch(authActions.removeUser());
    // history.push("/")
  }

const searchHandler=(e)=>{
  let values=e.target.value
  setSearchVal(values)
  let request={
    search:values
  }
  getfun(request)
 
}
useEffect(() =>{
  filterTest()
},[pageChange,pageSize])
useEffect(() =>{
 
  getfun()
},[Status])
const handlePageChange = (page, pageSize) => {
  setPageChange(page);
  setPageSize(pageSize);
};
const handlePaginationChange = (e) => {
  setPageChange(e);
};
 

  return (
    <>
    
        
          <h1 style={{ margin: "1% 0 0 2%",fontWeight:"bold",fontSize:"1.5rem"}}>
            Test Paper Creation
          </h1>
          
    <div
      style={{
        padding: " 0.5% 2% 2% 2%",
        width: "100%",
        boxSizing:"border-box",
        height: "82vh",
      }}
    >
        {/* <Col span={4} offset={8}>
          <Button style={{ width: "100%", backgroundColor: "black", color: "white", marginTop: "-10px" }} size="large" onClick={logoutHAndler}>Logout</Button>
        </Col> */}
     
      <Row style={{display:"flex",flexDirection:"row",alignItems:"center"}}>
      <div style={{ display: 'flex',justifyContent:"space-between",width:"100%",alignItems:"center"}}>  
            <div className={classes.searchDiv2}>
                      <Input
                      style={{width:"17rem"}}
                      prefix={<SearchOutlined className="custom-search-icon"/>}
                      size="middle"
                      type="text"
                      allowClear={true}                   
                      placeholder='Search'
                      onChange={searchHandler}
                      />                    
                </div>           
                       
            <div style={{ display: 'flex', gap:".7rem",justifyContent:"space-between",alignItems:"center",}} >
              {btnVisible && selectedRow.length>0 &&
              <Button
              size="middle"
              style={{width:"10rem",alignItems:"center",display:'flex',justifyContent:"center"}}
               type="primary"
              onClick={()=>{
                Api.patch("/hiring-tests/status").params({
                  testIds:selectedRow,
                  status:Status==="Active"?"InActive":"Active"
                }).send(resp=>{
                  if(resp){
                 
                   setSelectedRow([])
                   setBtnVisible(false)
                   getfun()
                    // window.location.reload();
                    setRefresh(prev=>!prev)
                  }
                  else
                  {
                   
                  }
                })
              }}
              >
                
                <p
                 style={{ color: "#ffffff",fontWeight:"600", }}>{Status==="InActive"?"Active":"Inactive"}
                </p>
                </Button>}
                       {/* <Dropdown overlay={<SettingDropdown/>} placement="bottom" arrow>
                                <img src={Settings}
                                  style={{ width: "24", height: "20px", marginTop: "9px", marginRight: '10px' }} onClick={()=>setSettingModalOpen(true)} />
                        </Dropdown>  */}

                    <div className="head-icon" >
                           {sortPopover("sort",<img src={Sort} onClick={()=>{setSortModalOpen(true)}} style={{ width:"20px",height:'20px'}}/>)}
                      </div>


                      <div className="head-icon">
                                {sortPopover ("filter-details",<img src={filter} onClick={()=>{}} style={{ width:"20px",height:'20px'}}/>)}
                      </div>
                        <Dropdown overlay={<div> {""}</div>} placement="bottom" arrow>
                                  <img src={Download}
                                    style={{ cursor:"pointer", width: "25px", height: "25px",  marginRight: '10px' ,fontWeight:"bold"}}  onClick={() =>filterTest("download")}/>
                        </Dropdown>
                        <div style={{display:"flex",alignItems:"center"}}>
                                 {!btnVisible&& !update&&<CreateTest setLoader={setLoader} refresh={getfun.bind(this)}/>}
                        </div>
              </div>
           
          </div></Row>
          {console.log("statussss",Status)}
      <Tabs
        // defaultActiveKey="ACTIVE"
        activeKey={Status}
        style={{fontWeight:"800", fontSize:"16px"}}  
       onChange={(key)=>{
        setBtnVisible(false)
       setSelectedRow([])
       dispatch(testActions.setStatus(key));
      //  setStatus(key)
        // if(key==="Active")
        // { 
        //   setStatus("InActive")
        // }
        // else if(key==="InActive")
        // { 
        //   setStatus("Active");
        // }       
      }
       }      
       >
        <TabPane tab={<span style={{fontSize:"14px",fontWeight:"800"}}>ACTIVE</span>} key="Active">
        <div>
               {Status==="Active"&&<Table key={Status} dataSource={data("Active")} columns={columns} loading={loader}  rowClassName={classes.rowStyle} className={classes.table}
                rowSelection={rowSelection}
                scroll={{x:"1400",y:"500"}}
                pagination={{
                  showSizeChanger: true,
                  pageSizeOptions: [10, 30, 50, 100],
                  pageSize: meta?.itemPerPage,
                  total: meta.totalItems,
                  onShowSizeChange: handlePageChange,
                  onChange: handlePaginationChange,
                }}
                 />}               
          </div>
           </TabPane >
        <TabPane tab={<span style={{fontSize:"14px",fontWeight:"800"}}>INACTIVE</span>} key="InActive" >
        {Status==="InActive"&&<Table key={Status} dataSource={data("InActive")} columns={columns} loading={loader}  rowClassName={classes.rowStyle} className={classes.table}
                rowSelection={rowSelection}
                scroll={{x:"1400",y:"500"}}
                pagination={{
                  showSizeChanger: true,
                  pageSizeOptions: [10,20, 30, 50, 100],
                  pageSize: meta.itemPerPage,
                  total: meta.totalItems,
                  onShowSizeChange: handlePageChange,
                  onChange: handlePaginationChange,
                }}
                 />}
           </TabPane>
      </Tabs>

      <Modal visible={secModal} footer={false} onCancel={handleCancel} 
      closable={true}
      maskClosable={true}
      width="48rem" 
      centered
     >
      <div style={{margin:"1.5rem 1rem",}}>
                <Row justify="center" style={{marginTop:"20px",marginBottom:"0.5rem"}}>
                  <h2 style={{fontWeight:"700",fontSize:"1.5rem",marginBottom:"0.5rem"}}>Section Details</h2>
                  </Row>
                  <Row style={{ display:"flex",marginBottom:"0.7rem",justifyContent:"right",marginRight:"0.4rem"}}>
                      <Button style={{backgroundColor:'#0086FF',cursor:'pointer',color:"#ffffff",border:"1px solid #0086FF",fontWeight:"700"}}
                       onClick={()=>{
                       
                        Api.post("/hiring-tests/sectionWiseDetails")
                           .params({
                                testId:ids,
                                download:true,
                             })
                           .send((response)=>{
                            
                               if(typeof response != "undefined"){
                                // download(response, "_blank");
                                window.open(response, "_blank")?.focus();
                              }
                            })
                        // setDownRes(true)
                       }}>
                         <DownloadOutlined style={{fontSize:"17px",fontWeight:"bold"}}
                        /> Download Details
                      </Button>
                  
                  </Row>
                 <div className={classes.secModal}>
                {details.map((item,index)=>{
                      let secArr=[item]
                      let temp=secArr.map((item,index)=>{
                        return {...item, key:index}
                      })
                     

                      return (
                        
                                  <div className={classes.secDetailCard}>
                                        <h3>Sectional Details</h3>
                                        <div>
                                                    <div>
                                                                <img src={Book} 
                                                                      style={{size:"small",textAlign:"left",width:"6.5rem",height:"6.5rem", borderRadius:"50%",backgroundColor:"#DDDDDD",border:"1.3px solid blue",padding:"0.7rem",borderStyle:"dashed"}} 
                                                                      alt="Hi">
                                                                </img>                                         
                                                    </div>
                                                    <div>
                                                                  <div>
                                                                              <h3>SECTION NAME:</h3>
                                                                              <h3 >{item?.sectionTitle}</h3>
                                                                  </div>
                                                                            
                                                                  <div>
                                                                              <h3>TOTAL MARKS:</h3>
                                                                              <h3 >{item?.sectionMaxMarks}</h3>
                                                                  </div>
                                                      
                                                      </div>
                                                      <div>
                                                                    <div>
                                                                                <h3>TOTAL QUESTIONS:</h3>
                                                                                <h3>{item?.question?.length}</h3>
                                                                    </div>
                                                                    <div>
                                                                                  <h3>OVERALL CUTOFF:</h3>
                                                                                  <h3>{item?.sectionCutt_off}</h3>
                                                                    </div>
                                                      
                                                      </div>
                                          </div>
                                                    {/* <Card style={{width:"550px",textAlign:"center",boxShadow:" 2px 3px 5px #B4B0B0"}}
                                                    key={index} >
                                                    <Row style={{marginTop:"10px"}}>
                                                    
                                                    <Col style={{marginRight:"40px"}}>
                                                      <h3 style={{fontWeight:"bold",fontSize:"18px",marginLeft:"20px"}}>Sectional Details</h3>
                                                      </Col>
                                                      <Col style={{marginRight:"20px"}}>
                                                      <h3 style={{color:"#BFBFBF"}}>SECTION NAME:</h3>
                                                      </Col>
                                                      <Col>
                                                      <h3 style={{color:"#BFBFBF"}}>TOTAL QUESTION:</h3>
                                                      </Col>
                                                    </Row>

                                                  

                                                    <Row style={{width:"100%",display:"flex",flexDirection:"row",gap:"1rem",}}>  
                                                    <Col span={7} style={{marginLeft:"4%",justifyContent:"center",display:"flex",}}>
                                                    <img src={Book} 
                                                    style={{size:"small",textAlign:"left",width:"6.5rem",height:"6.5rem", borderRadius:"50%",backgroundColor:"#DDDDDD",border:"1.2px solid blue",padding:"0.7rem"}} 
                                                    alt="Hi"></img>
                                                    </Col>                    
                                                      <Col  span={7} style={{justifyContent:"center",display:"flex"}}>
                                                      <h3 >{item?.sectionTitle}</h3>
                                                      </Col>
                                                      <Col  span={7} style={{justifyContent:"center",display:"flex"}}>
                                                      <h3>{item?.question?.length}</h3>
                                                      </Col>
                                                    </Row>

                                                    <Row>
                                                      <Col style={{marginRight:"215px"}}>
                                                      </Col>
                                                      <Col style={{marginRight:"25px"}}>
                                                      <h3 style={{color:"#BFBFBF"}}>TOTAL MARKS:</h3>
                                                      </Col>
                                                      <Col>
                                                      <h3 style={{color:"#BFBFBF"}}>OVERALL CUTOFF:</h3>
                                                      </Col>
                                                    </Row>

                                                    <Row style={{marginTop:"1px",marginBottom:"-5px"}}>
                                                      <Col style={{marginRight:"215px"}}>
                                                      </Col>
                                                      <Col style={{marginRight:"65px",marginLeft:"45px"}}>
                                                      <h3 >{item?.sectionMaxMarks}</h3>
                                                      </Col>
                                                      <Col>
                                                      <h3 style={{marginLeft:"70px"}}>{item?.sectionCutt_off}</h3>
                                                      </Col>
                                                    </Row>
                                                  </Card> */}
                                  </div>
                        
                     ) 
                     })} </div>
                 
                  </div>
        </Modal>
      

      {/* 
      <Row style={{ marginLeft: "5px" }}>
        <Col span={12}>
          <Title level={3}>All tests({count})</Title>
        </Col>
       
        <Col span={6}>
          <Input.Search placeholder="Search By Title..." size="large" onChange={onSearch}></Input.Search>
        </Col>
        <Col span={5} offset={1}>
          <CreateTest setLoader={setLoader} />
        </Col>
      </Row> 
      <Row>
      <Col span={24}> 
       <Table dataSource={data} columns={columns} loading={loader} />
        </Col>
        </Row> */}
   {update &&<EditTest selectedObj={selectedObj} setUpdate={setUpdate} update={update} refresh={getfun.bind(this)}/>}
    </div>
    </>
  );
};


export default TestList;