import { useDispatch, useSelector } from 'react-redux';
import LogoutPalette from './headers';
import { useWindowDimensions } from './layout';
import classes from './mainHeader.module.css';
import brigoLogoMob from '../assets/brigoIconMob.svg';
import mob_home_icon from '../assets/mob_home_icon.svg'
import { moduleActions } from './store';
import { useHistory 
} from "react-router-dom";
const MainHeader=(props)=>{
    const {width}=useWindowDimensions();
    const {user} = useSelector(state =>state.auth);
    const dispatch=useDispatch();
    const history = useHistory();
    const logoutHAndler = () => {
        if(user?.role?.includes("Agency") || user?.role?.includes("College-Admin")){
          window.localStorage.clear()
          window.location.replace("https://login.brigosha.com");
        }else{
          // dispatch(authActions.removeUser());
          history.goBack();
          history.goBack();
          window.location.replace("https://login.brigosha.com/homePage")
        }
        };
    let mobileNav=<svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 22 20" fill="none">
    <path d="M20.8389 8.77979H1.16111C0.519847 8.77979 0 9.46039 0 10.3V10.46C0 11.2996 0.519847 11.9802 1.16111 11.9802H20.8389C21.4802 11.9802 22 11.2996 22 10.46V10.3C22 9.46039 21.4802 8.77979 20.8389 8.77979Z" fill="#162432"/>
    <path d="M20.8389 16.7808H1.16111C0.519847 16.7808 0 17.4614 0 18.3009V18.461C0 19.3005 0.519847 19.9811 1.16111 19.9811H20.8389C21.4802 19.9811 22 19.3005 22 18.461V18.3009C22 17.4614 21.4802 16.7808 20.8389 16.7808Z" fill="#162432"/>
    <path d="M20.8389 0.778809H1.16111C0.519847 0.778809 0 1.45941 0 2.29899V2.459C0 3.29858 0.519847 3.97918 1.16111 3.97918H20.8389C21.4802 3.97918 22 3.29858 22 2.459V2.29899C22 1.45941 21.4802 0.778809 20.8389 0.778809Z" fill="#162432"/>
    </svg>
    return(
        <div className={classes.mainHeader}>
            <div >
            {width>700?<h1>Hiring Management</h1>:
            <div style={{display:"flex",alignItems:"center",justifyContent:"center",gap:"1rem",paddingLeft:"0.5rem"}}>
               <span onClick={()=>{
                dispatch(moduleActions.setSidemenu(true));
            }}> {mobileNav}</span>
               <img src={brigoLogoMob}/>
            </div>}
            </div>         
                {width>700?<LogoutPalette/>:<img src={mob_home_icon} style={{width:"2rem",height:"2rem"}} onClick={logoutHAndler}/>}              
        </div>
    )
}
export default MainHeader;